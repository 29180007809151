<template>
    <client-page>
        <sub-visual
        sh="회사소개"
        sc="에프원시큐리티가 여러분의 정보를 함께 보호하겠습니다."
        tabActive="인사말"
        icon="/images/sub/visual/sv1-icon.svg"
        bg="/images/sub/visual/sv1.png">
        </sub-visual>

        <section class="sub-section pb-28 pb-md-28 pb-lg-54 greeting-bg">
            <v-container>
                <div class="sub-tit-wrap">
                    <h3 class="sub-tit--lg mb-12 mb-lg-24" data-aos="fade-up">
                        <span class="color-point">안전한 디지털 보안기술</span> 의 <br class="d-none d-md-block">
                        파트너 F1 Security 입니다
                    </h3>
                    <p class="page-text--lg color-gray">
                        에프원시큐리티는 정보보호컨설팅, 솔루션 개발을 전문으로 하는 여러분의 보안 파트너입니다. 
                    </p>
                </div>
            </v-container>
        </section>

        <div class="greeting-bg2"></div>

        <section class="pt-40 pt-md-40 pt-lg-80 sub-section--last">
            <v-container>
                <p class="page-text color-gray mb-8 mb-lg-20">
                    안녕하십니까? 에프원시큐리티 대표이사 이대호입니다.
                </p>
                <p class="page-text color-gray mb-8 mb-lg-20">
                    에프원시큐리티는 2011년 설립이래 고객의 소중한 정보자산을 안전하게 보호하기 위한  보안솔루션과 서비스를 제공하며 <br class="d-none d-lg-block">
                    ISMS, ISMS-P, ISO27001 등 인증컨설팅과 개인정보보호/관리체계 컨설팅을  핵심 사업분야로 하고 있습니다. 
                </p>
                <p class="page-text color-gray mb-8 mb-lg-20">
                    다년간 다양한 고객의 정보보호 컨설팅을 기반으로 웹방화벽/웰쉘탐지/웹악성코드분석탐지 등 <br class="d-none d-lg-block">
                    솔루션의 필요성을 가지고 자사 솔루션 개발에 지속적으로 노력해왔습니다. 
                </p>
                <p class="page-text color-gray mb-20 mb-lg-50">
                    고객 가치를 극대화하기 위한 정보보호 컨설팅, 정보보호 솔루션 개발을 전문으로 세계 시장을 대표하는 보안 기업으로 정진하는 정보보호 <br class="d-none d-lg-block">
                    파트너로 쉬지 않고 노력하겠습니다. 다가올 미래는 정보보호가 IT서비스의 경쟁력이 될 수 있는 시대라고 판단되며, <br class="d-none d-lg-block">
                    에프원시큐리티와 함께 정보보호 향상을 고객과 같이 실현 할 수 있도록 노력하겠습니다. <br>
                    앞으로도 변함없는 성원 부탁드리며, 고객 여러분들의 든든한 파트너가 될 것을 약속합니다. <br>
                    감사합니다.
                </p>
                <p class="page-text">
                    (주)에프원시큐리티 대표이사 <strong class="font-weight-bold color-point">이대호</strong>
                </p>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
export default {
    components: {
        ClientPage,
        SubVisual,
    },
};
</script>

<style lang="scss" scoped>
.greeting-bg2{
    background-image: url(/images/sub/about/greeting/greeting-img.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 160px;
}

@media (min-width:576px){
}
@media (min-width:768px){
    .greeting-bg2{
        height: 240px;
    }
}
@media (min-width:1024px){
    .greeting-bg{
        background-image: url(/images/sub/about/greeting/greeting-bg.png);
        background-repeat: no-repeat;
        background-position: right 70px bottom;
    }
    .greeting-bg2{
        height: 360px;
    }
}
@media (min-width:1200px){
}

</style>